import React, { useEffect, useState } from "react";
import { useStaticQuery, Link } from "gatsby";
import Image from "gatsby-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRss, faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const Layout = ({ location, title, children }) => {
  const [toggleMenu, setToggleMenu] = useState(undefined);
  const [currentOffsetY, setCurrentOffsetY] = useState(undefined);

  function toggleMenuClick() {
    if (toggleMenu) {
      setToggleMenu(false);
    } else {
      setToggleMenu(true);
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const currentOffsetY = window.pageYOffset;
      setCurrentOffsetY(currentOffsetY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      logo: file(absolutePath: { regex: "/logo-pic.png/" }) {
        childImageSharp {
          fixed(height: 30, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      smallLogo: file(absolutePath: { regex: "/logo-pic.png/" }) {
        childImageSharp {
          fixed(height: 15, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
          }
          copyright
        }
      }
    }
  `);

  const copyright = data.site.siteMetadata.copyright;
  const author = data.site.siteMetadata.author;
  const logo = data.logo.childImageSharp.fixed;
  const smallLogo = data.smallLogo.childImageSharp.fixed;
  const rootPath = `${__PATH_PREFIX__}/`;

  const isRootPath =
    location.pathname === rootPath ||
    location.pathname === "/design" ||
    location.pathname === "/news";
  let navBarHeader;
  let header;

  if (isRootPath) {
    navBarHeader = (
      <h1 className="navbar-heading">
        {title}
        <Link to="/" title={title}>
          <Image className="big-logo" fixed={logo} alt={author.name || ``} />
          <Image
            className="small-logo"
            fixed={smallLogo}
            alt={author.name || ``}
          />
        </Link>
      </h1>
    );
  } else {
    navBarHeader = (
      <Link to="/" title={title}>
        {title}
        <Image className="big-logo" fixed={logo} alt={author.name || ``} />
        <Image
          className="small-logo"
          fixed={smallLogo}
          alt={author.name || ``}
        />
      </Link>
    );

    header = (
      <Link className="header-link-home" to="/" title={title}>
        {title}
      </Link>
    );
  }

  return (
    <div>
      <div className={"toggle-nav-mask " + (toggleMenu ? "show" : "")}></div>
      <div className={"toggle-nav-container " + (toggleMenu ? "show" : "")}>
        <div className="header">
          <Link to="/" title={title}>
            <Image fixed={smallLogo} alt={author.name || ``} />
          </Link>
          <button className="btn-menu" onClick={toggleMenuClick}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <ul>
          <li>
            <Link className="" to="/design" activeClassName="active-link">
              Design
            </Link>
          </li>
          <li>
            <Link className="" to="/news" activeClassName="active-link">
              News
            </Link>
          </li>
          <li>
            <a href="https://nextinnovation.kr/career_list.do" target="_blank">
              Career
            </a>
          </li>
        </ul>
      </div>
      <div className={"navbar " + (currentOffsetY ? "navbar-sticky" : "")}>
        <div className="navbar-container">
          <button className="btn-menu" onClick={toggleMenuClick}>
            <FontAwesomeIcon icon={faBars} />
          </button>
          <div className="logo">{navBarHeader}</div>
          <div className="menu">
            <ul className="menu-container">
              <li>
                <Link className="" to="/design" activeClassName="active-link">
                  Design
                </Link>
              </li>
              <li>
                <Link className="" to="/news" activeClassName="active-link">
                  News
                </Link>
              </li>
              <li>
                <a
                  href="https://nextinnovation.kr/career_list.do"
                  target="_blank"
                >
                  Career
                </a>
              </li>
              <li className="rss-icon">
                <a href="/rss.xml" target="_blank">
                  <FontAwesomeIcon icon={faRss} />
                </a>
              </li>
            </ul>
          </div>
          <a className="btn-rss" href="/rss.xml" target="_blank">
            <FontAwesomeIcon icon={faRss} />
          </a>
        </div>
      </div>
      <div className="global-wrapper" data-is-root-path={isRootPath}>
        <main>{children}</main>
        <footer className="main-footer">
          <div className="container">
            <div className="list-container">
              <div className="row">
                <address>
                  <h1>채용문의</h1>
                  <ul>
                    <li>
                      <a
                        href="mailto:job@nextinnovation.kr"
                        title="job@nextinnovation.kr"
                      >
                        job@nextinnovation.kr
                      </a>
                    </li>
                    <li>
                      <a href="tel:02-6337-0310" title="02-6337-0310">
                        02 6337 0310
                      </a>
                    </li>
                  </ul>
                </address>
                <address>
                  <h1>기타문의</h1>
                  <ul>
                    <li>
                      <a href="mailto:next@nextinnovation.kr" title="사업/제휴">
                        사업 / 제휴
                      </a>
                    </li>
                    <li>
                      <a href="mailto:next@nextinnovation.kr" title="IR">
                        IR
                      </a>
                    </li>
                  </ul>
                </address>
                <address>
                  <h1>넥스트이노베이션</h1>
                  <ul>
                    <li>
                      <a
                        href="https://nextinnovation.kr/business.do"
                        target="_blank"
                        rel="noreferrer"
                        title="비즈니스"
                      >
                        비즈니스
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://nextinnovation.kr/career_list.do"
                        target="_blank"
                        title="채용"
                      >
                        채용
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://m.blog.naver.com/PostList.nhn?blogId=stamppop"
                        target="_blank"
                        title="스탬프 팝"
                      >
                        스탬프 팝
                      </a>
                    </li>
                  </ul>
                </address>
                <address>
                  <p>
                    Copyright ©{` `}
                    <a
                      href="https://blog.nextinnovation.kr"
                      title="(주)넥스트이노베이션"
                    >
                      {copyright}
                    </a>
                    <br /> All rights reserved.
                  </p>
                  <p>
                    사업자등록번호 107-87-87880 <span>|</span>
                    <br /> 대표 노지철
                  </p>
                  <p>
                    서울특별시 광진구 천호대로 572 범천타워 13층
                    넥스트이노베이션
                  </p>
                </address>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Layout;
